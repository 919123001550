<template>
    <select v-model="amount" @change="changed()">
        <option selected value="0">Number of Onis to Mint</option>
        <option value="1">1 Oni</option>
        <option value="2">2 Oni</option>
        <option value="3">3 Oni</option>
    </select>

</template>
<script src="./Select.js"></script>
<style lang="scss" scoped>
    @import './Select.scss';
</style>
