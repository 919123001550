export default {
  name: 'Select',
  data() {
    return {
      amount: '1',
    };
  },
  methods: {
    changed() {
      this.$emit('selection', this.amount);
    },
  },
};
