<template>
    <nav>
        <div class="nav-container">
            <ul class="nav-list nav-links">
                <li><a href="/#"><img src="../../static/img/small-logo.png" class="small-logo" alt="Oni Squad Logo"></a></li>
                <li v-if="showHomeLinks" class="hide hide13"><a href="#features">Features</a></li>
                <li v-if="showHomeLinks" class="hide hide13"><a href="#roadmap">Roadmap</a></li>
                <li v-if="showHomeLinks" class="hide hide13"><a href="#team">Team</a></li>
                <li v-if="!showHomeLinks" class="hide"><router-link to="/">Home</router-link></li>
                <li class="yellow"><a @click='open("https://opensea.io/collection/onisquad")' href="javascript:void(0)" target="_blank"><img src="../../static/img/social/opensea-boat-yellow.png" alt="opensea" class="opensea-nav">Buy an Oni</a></li>
                <li class="yellow hide" >
                    <a class="mint-link" href="javascript:void(0)" @click='open("https://raritysniper.com/oni-squad")'>Rarity</a>
                </li>
                <li class="yellow hide" v-if="balance > 0 && showReveal" @click="reveal()">
                    <a class="mint-link" href="javascript:void(0)">Reveal</a>
                </li>
                <li>
                    <div class="connect-button hide" >
                        <div class="contents inline-menu">
                            <div class="connect-btn-outer">
                                <div  @click="checkConnection(connected)"  class="connect-btn-inner">
                                    <a class="mint-link" href="javascript:void(0)"> <span class="dot" :class="{'green': connected, 'red': !connected}"></span> <span>{{connected ? 'Connected': 'Disconnected'}}</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="nav-list nav-social">
                <li class="hide">Join Us:</li>
                <li><a href="https://medium.com/@onisquad" target="_blank"> <img class="sm-small-icons" src="../../static/img/social/tnav_medium_black.png"></a></li>
                <li><a href="https://opensea.io/collection/onisquad" target="_blank"> <img class="sm-small-icons" src="../../static/img/social/tnav_opensea_black.png"></a></li>
                <li><a href="https://twitter.com/onisquadgg" target="_blank"><img class="sm-small-icons" src="../../static/img/social/tnav_twitter_black.png" alt="twitter icon"></a></li>
                <li><a href="https://discord.com/invite/onisquad" target="_blank"><img class="sm-small-icons" src="../../static/img/social/tnav_discord_black.png" alt="discord icon"></a></li>

            </ul>
        </div>
    </nav>
</template>
<style scoped src="./Navbar.css"></style>
<script src="./Navbar.js"></script>
