<template>
    <div>
        <!-- VUEJS COMPONENTS -->
        <!-- <Loader :loading="loading"></Loader> -->
        <!-- <Modal :message="modalMessage" :showModal="showModal" @close-modal-instance="close"></Modal> -->
        <!-- END VUEJS COMPONENTS -->
        <div class="modal-content" >
            <!-- <h1 class="m-counter" v-if="connected && (mintCountAvailable > 0)">{{mintCountAvailable}} Oni Left</h1> -->
            <h1 class="m-counter">Sold Out!</h1>
            <div class="contents text-center">
                <div class="p-discord">
                    <!-- <Select v-if="connected" @selection="setSelectedAmount($event)"></Select> -->
                    <!-- <Select v-if="connected" @selection="setSelectedAmount($event)"></Select> -->
                    <!-- <div @click="mint()" v-if="connected" class="p-btn" :class="{'ml-15' : connected}">
                        <a class="mint-link" v-bind:class="{'button-disabled': !maxOniLimit}" href="javascript:void(0)">Mint</a>
                    </div> -->
                    <div @click="reveal()" class="p-btn ml-15" v-if="balance > 0">
                        <a class="mint-link" href="javascript:void(0)">Reveal</a>
                    </div>
                    <!-- <div  @click="checkConnection(connected)"  class="p-btn ml-15">
                        <a class="mint-link" href="javascript:void(0)"><span class="dot" :class="{'green': connected, 'red': !connected}"></span> {{connected ? 'Connected': 'Disconnected'}}</a>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="text-center">
            <span>
                <abbr data-title="If you have a hardware wallet, you may encounter an issue with the mint. This is due to EIP-1559, Metamask wallet accounts integrated with hardware wallets (i.e. Trezor, Ledger) will NOT be able to mint, and will receive an error message instead. Normal Metamask ETH addresses not connected to a hardware wallet will mint correctly.">
                    have a hardware wallet?
                </abbr>
            </span>
        </div> -->
    </div>
</template>
<script src="./Mint.js"></script>