/* eslint-disable no-unused-vars */
import Select from '../select/Select.vue';
import Modal from '../modal/Modal.vue';
import Loader from '../loader/Loader.vue';
import { Web3NFTService } from '../../services/Web3NFTService';
import { ERROR_MESSAGES } from '../../consts/Messages';
import axios from 'axios';

export default {
  name: 'MintComponent',
  components: {
    Select,
    Modal,
    Loader,
  },
  data() {
    return {
      balance: 0,
      maxBalance: 1,
      maxMint: 6666,
      currentCount: 0,
      minted: false,
      formInput: {
        amount: 1,
      },
      /**
       * @type { Web3NFTService }
       */
      web3NFTService: new Web3NFTService(),
      account: null,
      showModal: false,
      modalMessage: '',
      connected: false,
    };
  },
  methods: {
    accountsWatch() {
      if (this.web3NFTService.provider) {
        this.web3NFTService.provider.on('accountsChanged', (accounts) => {
          this.account = accounts[0];
          this.web3NFTService.account = accounts[0];
          this.getBalance();
          this.maxByMint();
        });
        this.web3NFTService.provider.on('chainChanged', async (accounts) => {
          this.balance = 0;
          this.maxBalance = 1;
          if (!this.connected) {
            this.getweb3NFTService();
          }
          await this.checkConnection();
          await this.getBalance();
          await this.maxByMint();
        });
      }
    },
    checkConnection(status = true) {
      if (!status) {
        this.$emitter.emit('open-modal-root', ERROR_MESSAGES.WRONG_NETWORK);
      }
      this.web3NFTService.checkNetwork().then((connected) => {
        this.connected = connected;
      });
    },
    methodsCall() {
      this.accountsWatch();
      this.checkConnection();
      this.getBalance();
      this.maxByMint();
      this.currentMintedCount();
    },
    currentMintedCount() {
      this.web3NFTService.totalMint().then((res) => {
        this.currentCount = res;
      });
    },
    getweb3NFTService() {
      this.web3NFTService
        .getConfig()
        .then((config) => {
          this.account = config.accounts[0];
          this.methodsCall();
        })
        .catch((err) => {
          this.methodsCall();
          console.error(err);
        });
    },
    maxByMint() {
      this.web3NFTService
        .maxByMint()
        .then((max) => {
          this.maxBalance = max;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async setMax() {
      this.web3NFTService.setMax();
    },
    async _baseURI() {
      const baseTokenURI = await this.web3NFTService.web3.methods.baseTokenURI().call();
    },
    async mint() {
      if (!this.maxOniLimit) {
        this.$emitter.emit('open-modal-root', ERROR_MESSAGES.MAX_NUMBER_ONIS);
      } else {
        this.$emitter.emit('toggle-loader', true);
        await this.web3NFTService.mint(
          this.formInput.amount,
          () => {
            setTimeout((t) => {
              this.minted = true;
              this.$emitter.emit('toggle-loader', false);
              this.getBalance().then(() => {
                this.tokenOfOwnerByIndex();
              });
            }, 2000);
          },
          (error) => {
            this.$emitter.emit('toggle-loader', false);
            this.$emitter.emit('open-modal-root', error);
            console.error(error);
          }
        );
      }
      // await this.web3NFTService.mintGas(
      //   this.formInput.amount,
      //   async (done) => {

      //   },
      //   (err) => {
      //     this.$emitter.emit('toggle-loader', false);
      //     this.$emitter.emit('open-modal-root', ERROR_MESSAGES.INSUFFICIENT_FUNDS);
      //   }
      // );
    },
    getBalance() {
      return new Promise((resolve, reject) => {
        this.web3NFTService
          .getBalance()
          .then((balance) => {
            console.log(this.balance);
            this.balance = balance;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async unpause() {
      this.web3NFTService.unpause();
    },
    async tokenOfOwnerByIndex() {
      this.web3NFTService.checkOwnerTokens(this.account).then((res) => {
        console.log(res);
        if (this.minted) {
          if (
            res.oniIDS.length > 0 &&
            parseInt(this.balance) === res.oniIDS.length &&
            !this.$router.currentRoute._value.fullPath.includes('minting')
          ) {
            this.$router.push({
              path: `/minting/${this.account}/${res.oniIDS.join(',')}`,
            });
          }
          this.minted = false;
        }
      });
    },
    reveal() {
      this.minted = true;
      this.tokenOfOwnerByIndex();
    },
    close() {
      this.showModal = false;
      this.modalMessage = '';
    },
    setSelectedAmount(amount) {
      this.formInput.amount = amount;
    },
  },
  computed: {
    maxOniLimit() {
      return this.balance < this.maxBalance;
    },
    mintCountAvailable() {
      return this.maxMint - this.currentCount;
    },
  },
  mounted() {
    this.getweb3NFTService();
  },
};
