import { Web3NFTService } from '../../services/Web3NFTService';

import MintComponent from '../../components/mint/Mint.vue';
import { ERROR_MESSAGES } from '../../consts/Messages';

export default {
  name: 'Navbar',
  components: {
    MintComponent,
  },
  props: {
    showReveal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      balance: 0,

      /**
       * @type { Web3NFTService }
       */
      web3Config: new Web3NFTService(),
      account: null,
      connected: false,
      showHomeLinks: true,
    };
  },
  methods: {
    accountsWatch() {
      if (this.web3Config.provider) {
        this.web3Config.provider.on('accountsChanged', async (accounts) => {
          this.account = accounts[0];
          this.web3Config.account = accounts[0];
          await this.getBalance();
        });
        this.web3Config.provider.on('chainChanged', async (accounts) => {
          this.balance = 0;
          if (!this.connected) {
            this.getWeb3Config();
          }
          await this.checkConnection();
          await this.getBalance();
        });
      }
    },
    methodsCall() {
      this.accountsWatch();
      this.checkConnection();
      this.getBalance();
    },
    getWeb3Config() {
      this.web3Config
        .getConfig()
        .then((config) => {
          this.account = config.accounts[0];
          this.methodsCall();
        })
        .catch((err) => {
          this.methodsCall();
          console.error(err);
        });
    },

    async getBalance() {
      return new Promise((resolve, reject) => {
        this.web3Config
          .getBalance()
          .then((balance) => {
            this.balance = balance;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async tokenOfOwnerByIndex() {
      this.web3Config.checkOwnerTokens(this.account).then((res) => {
        if (this.minted) {
          if (
            res.oniIDS.length > 0 &&
            parseInt(this.balance) === res.oniIDS.length &&
            !this.$router.currentRoute._value.fullPath.includes('minting')
          ) {
            this.$router.push({
              path: `/minting/${this.account}/${res.oniIDS.join(',')}`,
            });
          }
          this.minted = false;
        }
      });
    },
    reveal() {
      this.minted = true;
      this.tokenOfOwnerByIndex();
    },
    open(link) {
      window.open(link, '_blank');
    },
    checkConnection(status = true) {
      if (!status) {
        this.$emitter.emit('open-modal-root', ERROR_MESSAGES.WRONG_NETWORK);
        // this.showModal = true;
        // this.modalMessage = ERROR_MESSAGES.WRONG_NETWORK;
      }
      this.web3Config.checkNetwork().then((connected) => {
        this.connected = connected;
      });
    },
  },
  computed: {
    mintCountAvailable() {
      return this.maxMint - this.currentCount;
    },
  },
  mounted() {
    this.getWeb3Config();
    this.showHomeLinks = this.$route.path != '/mint';
  },
};
